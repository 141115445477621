// Header.js
import React from 'react';
import { Flex, Image, Heading } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import icon from '../assets/logo.png';

const Header = () => {
  return (
    <Flex
      // as={RouterLink}   
      // to="/"         
      alignItems="center"
      justifyContent="center"
      borderBottomWidth="2px"
      py="25px"
      textDecoration="none"
      _hover={{ textDecoration: 'none' }}
    >
      <Image src={icon} alt="hoo.be logo" boxSize="40px" mr={3} />
      <Heading size="lg" color="gray.800">
        hoo.be
      </Heading>
    </Flex>
  );
};

export default Header;

// NotFound.js
import React from "react";
import { Box, Text, Button, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/"); // Redirects to the home page
  };

  return (
    <Box minH="80vh" display="flex" alignItems="center" justifyContent="center">
      <VStack>
        <Text fontSize="3xl" fontWeight="bold">
          Uh Oh!
        </Text>
        <Text color="gray.600" mb={2}>This offer has either expired or is no longer active.</Text>
        <Button colorScheme="blue" onClick={handleGoHome}>
          Go to Home
        </Button>
      </VStack>
    </Box>
  );
};

export default NotFound;

// src/components/Application.jsx

import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Box,
  Text,
  Heading,
  Spinner,
  VStack,
  Image,
  Button,
  Input,
  Icon,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Link,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  CloseButton,
} from "@chakra-ui/react";
import { AiOutlineFileText, AiOutlineCheckCircle } from "react-icons/ai";
import Header from "../Header";
import NotFound from "./NotFound";

const Application = () => {
  const { applicationId } = useParams();
  const location = useLocation();
  const [applicationData, setApplicationData] = useState(null);
  const [application, setApplication] = useState({});
  const [answers, setAnswers] = useState([]);
  const [instagramHandle, setInstagramHandle] = useState("");
  const [instagramError, setInstagramError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_HOOBE_API_URL}/application-single`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ applicationId }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setApplicationData(data);
        const appData = data.application || {};
        setApplication(appData);

        if (appData.questions && appData.questions.length > 0) {
          setAnswers(Array(appData.questions.length).fill(""));
        }
      } catch (err) {
        console.error("Error fetching application data:", err);
        setError(
          "Failed to fetch application details. Please try again later."
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchApplication();
  }, [applicationId]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const instagramParam = params.get("instagram");
    if (instagramParam) {
      setInstagramHandle(instagramParam);
    }
  }, [location.search]);

  const handleInputChange = (index, value) => {
    setAnswers((prev) => {
      const newAnswers = [...prev];
      newAnswers[index] = value;
      return newAnswers;
    });
  };

  const handleApply = async () => {
    setInstagramError("");

    if (!instagramHandle.trim()) {
      setInstagramError("Instagram handle is required.");
      const instagramInput = document.getElementById(
        "instagram-handle-input"
      );
      if (instagramInput) {
        instagramInput.scrollIntoView({ behavior: "smooth" });
        instagramInput.focus();
      }
      return;
    }

    const allAnswersFilled =
      (application.questions && application.questions.length === 0) ||
      (application.questions &&
        answers.length === application.questions.length &&
        answers.every((answer) => answer.trim() !== ""));

    if (!allAnswersFilled) {
      toast({
        title: "Incomplete",
        description: "Please answer all the questions.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const questionAnswerPairs = application.questions
      ? Object.fromEntries(
          application.questions.map((question, index) => [
            question,
            answers[index].trim(),
          ])
        )
      : {};

    const applicationSubmitData = {
      instagram: instagramHandle.trim(),
      ...questionAnswerPairs,
      campaignId: applicationData.campaignId,
      workspaceId: applicationData.workspaceId,
    };

    try {
      setIsSubmitting(true);

      const response = await fetch(
        `${process.env.REACT_APP_HOOBE_API_URL}/application-apply`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(applicationSubmitData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message || "Failed to submit application."
        );
      }

      const responseData = await response.json();
      console.log("Application submitted successfully:", responseData);

      toast({
        title: "Success!",
        description: "Your application has been submitted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setAnswers(Array(application.questions.length).fill(""));
      setInstagramHandle("");
      onClose();
    } catch (err) {
      console.error("Error submitting application:", err);
      toast({
        title: "Submission Error",
        description: err.message || "An unexpected error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormValid = () => {
    const allAnswersFilled =
      (application.questions && application.questions.length === 0) ||
      (application.questions &&
        answers.length === application.questions.length &&
        answers.every((answer) => answer.trim() !== ""));
    const instagramFilled = instagramHandle.trim() !== "";
    return allAnswersFilled && instagramFilled;
  };

  if (isLoading) {
    return (
      <>
        <Header />
        <Box
          minH="80vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner size="xl" color="#38B2AC" />
        </Box>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Header />
        <Box
          minH="80vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text color="red.500">{error}</Text>
        </Box>
      </>
    );
  }

  if (!application.status) {
    return (
      <>
        <Header />
        <NotFound />
      </>
    );
  }

  const hostUrl = `https://hoo.be/${encodeURIComponent(
    application.hostHandle
  )}`;

  return (
    <>
      <Header />
      <Box minH="100vh" display="flex" flexDirection="column" mb={100}>
        <Box position="relative" height="400px" overflow="hidden">
          <Image
            src={application.image}
            alt="Application Image Blurred"
            width="100%"
            height="100%"
            objectFit="cover"
            filter="blur(30px)"
            transform="scale(1.2)"
            position="absolute"
            top="0"
            left="0"
          />
          <Image
            src={application.image}
            alt="Application Image"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            width="700px"
            height="320px"
            objectFit="cover"
            borderTopRadius="25px"
          />
        </Box>

        <Box bg="#ffffff" flex="1">
          <VStack align="center" maxW="700px" w="100%" mx="auto">
            <Box
              bg="#ffffff"
              borderRadius="25px"
              boxShadow="md"
              w="100%"
              overflowX="auto"
              mt="-125px"
              zIndex={100}
            >
              <Box
                h="125px"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottomWidth="2px"
                mb={4}
                mx={10}
              >
                <Box display="flex" alignItems="center">
                  <Image
                    src={applicationData.imageURL}
                    alt="Host Image"
                    boxSize="60px"
                    borderRadius="full"
                    mr={4}
                  />
                  <Heading as="h2" size="md" color="#333333">
                    hosted by{" "}
                    <Link href={hostUrl} color="blue.500" isExternal>
                      {application.hostHandle}
                    </Link>
                  </Heading>
                </Box>
                <Button
                  as="a"
                  href={hostUrl}
                  size="md"
                  colorScheme="blue"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </Button>
              </Box>

              <Box px={10} pb={10}>
                <Heading as="h1" size="xl" mb={6}>
                  {application.title}
                </Heading>

                {/* Module with different background color */}
                <Box
                  bg="#F6F7F8"
                  borderRadius="16px"
                  p={4}
                  mb={6}
                  display="flex"
                  alignItems="flex-start"
                >
                  <Icon
                    as={AiOutlineFileText}
                    boxSize={6}
                    color="#A2A6BB"
                    mr={4}
                    mt={1}
                  />
                  <Box>
                    <Text fontWeight="600" mb={2}>
                      About this event:
                    </Text>
                    <Text color="#51555C" whiteSpace="pre-wrap">
                      {application.description}
                    </Text>
                  </Box>
                </Box>

                <Box
                  bg="#F6F7F8"
                  borderRadius="16px"
                  p={4}
                  mb={6}
                  display="flex"
                  alignItems="flex-start"
                >
                  <Icon
                    as={AiOutlineCheckCircle}
                    boxSize={6}
                    color="#A2A6BB"
                    mr={4}
                    mt={1}
                  />
                  <Box>
                    <Text fontWeight="600" mb={2}>
                      Requirements:
                    </Text>
                    <Text color="#51555C" whiteSpace="pre-wrap">
                      {application.requirements}
                    </Text>
                  </Box>
                </Box>

                {/* Apply Button */}
                <Box display="flex" justifyContent="center" w="100%">
                  <Button
                    mt={6}
                    colorScheme="blue"
                    size="lg"
                    borderRadius="full"
                    onClick={onOpen}
                    w="200px"
                  >
                    Apply
                  </Button>
                </Box>

                {/* Questions Modal */}
                <Modal isOpen={isOpen} onClose={onClose} size="xl">
                  <ModalOverlay />
                  <ModalContent borderRadius="32px">
                    {/* Close Button at top-left */}
                    <Box position="absolute" top="3" left="3">
                      <CloseButton onClick={onClose} size="lg" />
                    </Box>
                    <ModalHeader textAlign="center" fontSize="2xl">
                      Application Questions
                    </ModalHeader>
                    <ModalBody>
                      <VStack align="start" spacing={4} w="100%">
                        {application.questions &&
                          application.questions.length > 0 &&
                          application.questions.map((question, index) => (
                            <Box key={index} w="100%">
                              <Text mb={2}>{question}</Text>
                              <Input
                                placeholder="Your answer"
                                value={answers[index] || ""}
                                onChange={(e) =>
                                  handleInputChange(index, e.target.value)
                                }
                              />
                            </Box>
                          ))}

                        <Box w="100%">
                          <FormControl
                            isRequired
                            isInvalid={instagramError}
                          >
                            <FormLabel htmlFor="instagram-handle-input">
                              What is your Instagram handle?
                            </FormLabel>
                            <Input
                              id="instagram-handle-input"
                              placeholder="@yourhandle"
                              value={instagramHandle}
                              onChange={(e) => {
                                setInstagramHandle(e.target.value);
                                if (instagramError) {
                                  setInstagramError("");
                                }
                              }}
                            />
                            {instagramError && (
                              <FormErrorMessage>
                                {instagramError}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </Box>
                      </VStack>
                    </ModalBody>
                    <ModalFooter justifyContent="center">
                      <Button
                        mt={6}
                        colorScheme="blue"
                        size="lg"
                        borderRadius="full"
                        onClick={handleApply}
                        w="200px"
                        isDisabled={!isFormValid() || isSubmitting}
                        isLoading={isSubmitting}
                        loadingText="Submitting"
                      >
                        Apply
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </Box>
            </Box>
          </VStack>
        </Box>
      </Box>
    </>
  );
};

export default Application;
